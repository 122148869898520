import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";
import ScrollToTop from "@components/shared/scrollToTop";
export default function SideImage({
    title,
    content,
    image,
    imageClassName,
    flip,
    showScrollButton = true,
    titleColor = "#000",
}: {
    title: string;
    content: string[];
    image: string;
    imageClassName: string;
    flip?: boolean;
    showScrollButton?: boolean;
    titleColor?: string;
}): ReactElement {
    return (
        <section className={`${styles["wrapper"]}`}>
            <div
                className={`py-8 md:py-24 gap-28 items-center justify-between flex flex-col lg:${
                    flip ? "flex-row-reverse" : "flex-row"
                } gap-7 px-5 `}
            >
                <div className={`lg:w-[50%] mx-auto lg:mx-0`}>
                    <h2 style={{ color: titleColor }}>{title}</h2>
                    {content.map((string, index) => (
                        <p key={index}>{string}</p>
                    ))}
                    {showScrollButton && (
                        <div className="mt-14 flex justify-center md:justify-start">
                            <ScrollToTop
                                colors={{
                                    bg: titleColor ?? "#be1e20",
                                    text: "white",
                                }}
                                className="rounded"
                            >
                                Get My Free Lawsuit Case Review
                            </ScrollToTop>
                        </div>
                    )}
                </div>
                <div className={`${imageClassName} relative  mx-auto lg:mx-0 `}>
                    <Image src={image} layout="fill" alt="Image" />
                </div>
            </div>
        </section>
    );
}
